<template>
	<section class="content">
		<table class="table table-hover" ref="tblshipper">
			<thead>
				<tr>
					<th>SHIPPER</th>
					<th>NAMA TOKO</th>
					<th>EMAIL</th>
					<th>NO. HP</th>
					<th>PIC</th>
					<th>ALAMAT</th>
					<th>DETAIL ALAMAT</th>
				</tr>
			</thead>
			<tbody @click="handleClick"></tbody>
		</table>

		<!-- form add pickup address per shipper id -->
		<div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit.prevent="submitWarehouse">
						<input type="hidden" name="id" id="id" v-model="form.id" />
						<div class="modal-header">
							<h5 class="modal-title font-weight-bold">{{ formTitle }}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="fi fi-rr-cross-small"></i></button>
						</div>
						<div class="modal-body">
							<p v-if="errors.length" class="alert alert-danger">
								<b>Please correct the following error(s):</b>
								<ul>
									<li v-for="error in errors">{{ error }}</li>
								</ul>
							</p>
							<div class="row">
                                <div class="col-md-12 form-group">
                                    <label class="control-label">Agen</label>
									<v-select
										label="name"
										:filterable="false"
										:options="shipperOptions"
										v-model="form.shipper_id"
										:reduce="(opt) => opt.shipper_id"
										required="required"
										@search="searchAgen"
									>
										<template slot="no-options"> Cari Agen </template>
										<template slot="option" slot-scope="option">
											<div class="d-center">
												{{ option.nama }}
											</div>
										</template>
										<template slot="selected-option" slot-scope="option">
											<div class="selected d-center">
												{{ option.nama }}
											</div>
										</template>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!form.shipper_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
                                </div>
								<div class="col-md-6 form-group">
									<label class="control-label">Label</label>
									<input type="hidden" name="row_id" value="" />
									<input
										id="sender_name"
										class="form-control"
										v-model="form.sender_name"
										type="text"
										required="required"
										name="sender_name"
										placeholder="Cth: Toko"
										onkeydown="return (event.ctrlKey || event.altKey 
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==46) || (event.keyCode==32) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"
									/>
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Nama Pengirim</label>
									<input type="hidden" name="row_id" value="" />
									<input
										id="pic_name"
										class="form-control"
										v-model="form.pic_name"
										type="text"
										required="required"
										name="pic_name"
										placeholder="Cth: Rajib Wijaya"
										onkeydown="return (event.ctrlKey || event.altKey 
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==46) || (event.keyCode==32) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"
									/>
									<small>Bisa disamakan dengan nama pengirim</small>
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">No. Telepon</label>
									<input
										id="pic_tel"
										pattern="0[0-9]{7,}|62[0-9]{7,}"
										formnovalidate
										class="form-control"
										v-model="form.pic_tel"
										type="text"
										required="required"
										name="pic_tel"
										placeholder="Cth: 085XXXXXXXXX"
										minlength="10" 
										maxlength="16"
										onkeydown="return (event.ctrlKey || event.altKey 
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==32) || (event.keyCode==9) 
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==46))"
									/>
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Kecamatan atau Kota</label>
									<v-select
										label="name"
										:filterable="false"
										:options="addrOptions"
										v-model="form.addr_id"
										:reduce="(opt) => opt.id"
										required="required"
										@search="onSearch"
										@input="setSelected"
									>
										<template slot="no-options"> Cari Kecamatan atau Kota </template>
										<template slot="option" slot-scope="option">
											<div class="d-center">
												{{ option.lokasi }}
											</div>
										</template>
										<template slot="selected-option" slot-scope="option">
											<div class="selected d-center">
												{{ option.lokasi }}
											</div>
										</template>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!form.addr_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
								<div class="col-md-12 form-group">
									<label class="control-label">Alamat Lengkap</label>
									<textarea
										id="addr_detail"
										name="addr_detail"
										class="form-control"
										v-model="form.addr_detail"
										required="required"
										minlength="15"
										placeholder="Tulis Alamat Lengkap"
										onkeydown="return (event.ctrlKey || event.altKey 
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==57	&& event.shiftKey==true)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==48	&& event.shiftKey==true)
										|| (event.keyCode==190	&& event.shiftKey==false) || (event.keyCode==188	&& event.shiftKey==false)
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==46) || (event.keyCode==32) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"
									></textarea>
								</div>
								<div class="col-md-6 form-group" v-if="false">
									<label class="control-label">Kode POS</label>
									<input
										id="kodepos"
										name="kodepos"
										class="form-control"
										v-model="form.kodepos"
										type="number"
										minlength="5"
										required="required"
									/>
								</div>
								<div class="col-md-6 form-group" v-if="false">
									<label id="labelCourierBranch" class="control-label">
										CABANG KURIR (KHUSUS JNE)
									</label>
									<v-select
										label="name"
										:options="courierBranchOption"
										v-model="form.code"
										:reduce="(opt) => opt.code"
									>
									</v-select>
								</div>
								<div class="col-md-6 form-group" v-if="false">
									<label class="control-label">ALAMAT JNE</label>
									<v-select
										label="addrName"
										:options="addrJne"
										v-model="form.addr_jne"
										:reduce="(opt) => opt.kode"
									>
									</v-select>
								</div>
								<div class="col-12 mt-3"></div>
								<div class="col">
									<button type="button" class="btn btn-block btn-outline-warning font-weight-bold py-3" data-dismiss="modal" :disabled="loading">Kembali</button>
								</div>
								<div class="col">
									<button type="submit" class="btn btn-block btn-warning text-light font-weight-bold py-3" :disabled="loading">Simpan Alamat
										<span v-if="loading" class="spinner-border spinner-border-sm" role="status"
											aria-hidden="true"></span></button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
	name: "gudangShipper",
	data() {
		return {
			errors: [],
			method: "",
			roles: "",
			formTitle: "",
			shipperOptions: [],
			addrOptions: [],
			form: {
				id: "",
				addr_id: "",
				addr_detail: "",
				shipper_id: "",
				pic_name: "",
				sender_name: "",
				pic_tel: "",
				kodepos: "",
				code: "",
				name: "",
				addr_jne: "",
				branch: ""
			},
			loading: false
		};
	},
	components: {
		vSelect
	},
	created: function () {
		this.roles = this.$route.params.roles;
	},
	methods: {
		handleClick(e) {
			if (e.target.matches(".link-role")) {
				this.$router.push({ path: "/permit/" + e.target.dataset.id });
				return false;
			}
		},
		submitWarehouse() {
			const e = this.$refs;
			var data = Object.keys(this.form).map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])).join("&");
			this.loading = true;

			authFetch("/order/address" + (this.method == 'PUT' ? "/" + this.form.id : ""), {
				method: this.method,
				body: data,
			}).then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			}).then((js) => {
				this.loading = false;
				this.errors = [];
				if (!js.success) {
					console.log(js.details);

					for (var key in js.details) {
						if (js.details.hasOwnProperty(key)) {
							this.errors.push(js.details[key]);
						}
					}

					return;
				}

				this.form.id = js.id;
				this.form.senderName = this.form.sender_name;
				this.form.senderTel = this.form.pic_tel;
				this.table.api().ajax.reload();
				$(e.formDialog).modal("hide");
			});
		},
		setSelected(value) {
			this.form.kodepos = value;
			var selectedAddress = this.addrOptions.filter((el) => {
				return el.id == value;
			});
			console.log(value);
			if (selectedAddress.length > 0) {
				this.form.kodepos = selectedAddress[0].kodepos;
				this.form.addr_jne = selectedAddress[0].kode_jne;
				this.form.branch = selectedAddress[0].kode_cabang;
			}
		},
		onSearch(search, loading) {
			if (search.length > 3) {
				loading(true);
				var vm = this;
				authFetch(`/order/wilayah?q=${escape(search)}`).then((res) => {
					res.json().then((json) => (vm.addrOptions = json.items));
					loading(false);
				});
			}
		},
		searchAgen(search, loading) {
			if (search.length > 3) {
                loading(true);
				var vm = this;
                authFetch(`/shipper/warehouse/agen?q=${escape(search)}`).then((res) => {
					res.json().then((json) => (vm.shipperOptions = json.items));
					loading(false);
				});
			}
		}
	},
	mounted() {
		const e = this.$refs;
		var self = this;
		this.table = createTable(e.tblshipper, {
			title: "",
			roles: this.roles,
			ajax: "/shipper/warehouse",
			processing: true,
			scrollX: true,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
			columns: [
				{
					data: "nama",
					render: function (data, type, row, meta) {
						return data + ' <div class="badge badge-' + (row.shipper_type == 1 ? 'agen' : 'cod') + '">bos' + (row.shipper_type == 1 ? 'Agen' : 'COD') + '</div>';
					},
				},
				{ data: "sender_name" },
				{ data: "email" },
				{ data: "pic_tel" },
				{ data: "pic_name" },
				{
					data: "gudang",
					render: function (data, type, row, meta) {
						return row.propinsi + ", " +row.kota + ", " + row.kecamatan;
					},
				},
				{ data: "addr_detail" },
			],
			filterBy: [0, 1, 2, 3, 4, 5, 6],
			rowCallback: function (row, data) {
			},
			buttonClick: (evt) => {
				if (evt.role != 'create' && evt.data.shipper_type == 0) {
					Swal.fire({
						title: "Hanya bisa mengubah pengguna bosAgen",
						icon: "error",
						confirmButtonText: 'Kembali'
					});
				} else if (evt.role == "create") {
					self.form = {};
					self.method = "POST";
					self.errors = [];
					self.formTitle = "Tambah Alamat Agen";
					$(e.formDialog).modal("show");
				} else if (evt.role == "update" && evt.data) {
					self.shipperOptions = [{
						shipper_id:  evt.data.shipper_id,
						nama:  evt.data.nama
					}];
					self.addrOptions = [{
						id: evt.data.addr_id,
						kodepos: evt.data.kodepos,
						addr_jne: evt.data.kode_jne,
						branch: evt.data.kode_cabang,
						lokasi: evt.data.kota + " \/ " + evt.data.kecamatan
					}];
					self.form = evt.data;
					self.method = "PUT";
					self.errors = [];
					self.formTitle = "Edit Alamat Agen";
					$(e.formDialog).modal("show");
				} else if (evt.role == "delete" && evt.data) {
					console.log(evt);
					Swal.fire({
						title: "Hapus data?",
						icon: "question",
						denyButtonText: '<i class="fa fa-times"></i> Hapus',
						showCancelButton: true,
						showDenyButton: true,
						showConfirmButton: false,
					}).then((result) => {
						if (result.isDenied) {
							authFetch("/order/address/" + evt.data.id, {
								method: "DELETE",
								body: "id=" + evt.data.id,
							}).then((res) => {
								return res.json();
							}).then((js) => {
								this.table.api().ajax.reload();
							});
						}
					});
				}
			},
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
		});
	},
};
</script>